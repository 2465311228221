<template>
  <div>
    <div>
      <!-- Register v1 -->
      <b-card class="mb-0" title="">
        <!-- form -->
        <validation-observer ref="createmeetingForm" #default="{ invalid }">
          <b-form @submit.prevent>
            <!-- meeting link-->
            <b-col cols="12">
              <b-form-group label="Meeting Link" label-for="meetingid">
                <validation-provider
                  #default="{ errors }"
                  name="Meeting Id"
                  vid="meeting_id"
                  rules="required"
                >
                  <b-form-input
                    id="register-meetingid"
                    v-model="form.meeting_id"
                    name="register-meetingid"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Meeting Id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- current month number -->
            <b-col cols="12">
              <b-form-group label="Current Month" label-for="currentmonth">
                <validation-provider
                  #default="{ errors }"
                  name="Current Month"
                  vid="Current Month"
                  rules="required|integer"
                >
                  <b-form-input
                    id="register-currentmonth"
                    v-model="form.current_month"
                    name="register-currentmonth"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter Current Month"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Button -->
            <b-form-group>
              <b-form-input id="h-email" type="text" hidden />
            </b-form-group>

            <b-button
              variant="primary"
              block
              type="submit"
              @click="createMeeting()"
              :disabled="invalid"
            >
              Submit
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Register v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import meetingApi from "@/Api/Modules/meetings";
import {
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BImg,
  BInputGroupAppend,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
export default {
  name: "Register",
  components: {
    vSelect,
    BCard,

    BCardTitle,
    BImg,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      form: {},
      options: [
        {
          title: "Active",
        },
        {
          title: "Expired",
        },
      ],
      selected: {
        title: "Active",
      },
      // validation rules
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
    };
  },

  methods: {
    async createMeeting() {
      if (await this.$refs.createmeetingForm.validate()) {
        await this.$vs.loading({
          scale: 0.8,
        });
        await meetingApi
          .createMeeting(this.form)
          .then(() => {
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
};
</script>
