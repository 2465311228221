import { api } from "../index";
import notification from "@/ApiConstance/toast";

export default {
  async createMeeting(payload) {
    return await api.post("/storemeet", payload).then(() => {
      notification.toast("SuccessFully Created a Meeting", "success");
    });
  },
  async allMeetings() {
    return await api.get("/getallmeetings");
  },

  async updateMeeting(payload, id) {
    return await api.put(`/updatemeeting/${id}`, payload).then(() => {
      window.location.href = "/meetings";
    });
  },

  async deleteMeeting(id) {
    return await api.put(`/deletemeeting/${id}`).then(() => {
      window.location.href = "/meetings";
    });
  },
};
